import React from "react"
import api from "../lib/api"
import { messages } from "../lib/messages"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import Icon from "../components/icon"
import { ContactForm } from "../components/forms"
import { BlackButton } from "../components/black-buttons"
import { Link } from "gatsby"
import "../style.css"

class ContactPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			phone: '',
			message: ''
		}
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	emailValid = () => {
		return this.emailRegex.test(this.state.email);
	}
	valid = () => {
		const s = this.state;
		return s.message.length > 2 && s.name.length > 2 && this.emailValid();
	}
	submit = async () => {
		if(this.valid()) {
			try {
				let req = { ...this.state };
				const response = await api.contactUs({ request: req });
				if(response.error) {
					messages.show('CONTACT_FAILED');
					return;
				}
				messages.show('CONTACT_SENT');
			} catch(e) {
				messages.show('CONTACT_FAILED');
			}
		}
	}
	render() {
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns cf'>

					<PageTitle title="Contact" />

					<div className='w-100 w-100-s w-50-ns fl pr5-m pr5-l pr5-xl'>
						<div>
							<h3 className='black-90 f4 f3-ns fw4'>Questions? We've got you covered.</h3>
							<p className='lh-copy black-90 f6 f5-ns'><Link className='blue no-underline hover-near-black' to="/faq">Check our FAQ</Link> for commonly-asked questions. If your answer isn't in there, feel free to contact us through this form. (Your phone number is optional, but in case you have a question about a specific order, we're happy to give you a call.)</p>
							<p className='lh-copy black-90 b f6 f5-ns'>We respond to all inquiries within 1 business day.</p>
							<p className='lh-copy black-90 f6 f5-ns'>If you need immediate assistance, give us a call at <a className='blue link dim' href="tel:1-832-919-7610">832.919.7610</a>. Office visits are by appointment, but we're happy to take your call Monday through Friday between 9am through 5pm, CST.</p>
						</div>
					</div>

					<div className='w-100 w-100-s w-50-ns fl mv4 mv0-l mv0-m mv0-xl bt bb b--black-05 bn-m bn-l bn-xl pv4 pv0-m pv0-l pv0-xl'>
						<ContactForm data={ this.state } onChange={ this.updateField } onSubmit={ this.submit } />
						<div className='pt2 tr'>
							<BlackButton title="Send" onClick={ this.submit } disabled={ !this.valid() } />
						</div>
					</div>

					<div className='w-100 fl tc pv5-ns mv3-m mv4-l mv4-xl'>
						<h3 className='arvo fw4 pa3 black-80 dib ba b--black-20 br1'>610 Design Studio</h3>
						<address className='pb4 lh-copy black-50'><span className='db'>1907 Sabine Street Ste 144 </span>Houston, TX 77007</address>
						<div>
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.09908022655!2d-95.37855898451984!3d29.774776438215287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640b8b4f1ae0d93%3A0xbc58fa666f4809c8!2s1907+Sabine+St+%23144%2C+Houston%2C+TX+77007!5e0!3m2!1sen!2sus!4v1529884228017" className='bn w-100 vh-25 vh-50-ns' allowFullScreen></iframe>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ContactPage;